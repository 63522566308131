<template>
    <div>
      <!-- fd-wrap -->
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
            <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                  >Minhas Plataformas</a
                >
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  :href="'/plataforma/' + $route.params.id_plataforma"
                  class="aluno_font_color"
                  @click.prevent="
                    $router.push('/plataforma/' + $route.params.id_plataforma)
                  "
                  >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a
                >
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                  @click.prevent="
                    $router.push(
                      '/plataforma/' + $route.params.id_plataforma + '/aluno'
                    )
                  "
                  >Modo aluno</a
                >
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a href="#">Pátio Virtual</a>
              </li>
            </ul>
            <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                  @click.prevent="
                    $router.push(
                      '/plataforma/' + $route.params.id_plataforma + '/aluno'
                    )
                  "
                  >Menu principal</a
                >
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a class="aluno_font_color" href="#">Pátio Virtual</a>
              </li>
            </ul>
          </nav>
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-ead-content mt-0">
          <div class="container">
            <div class="mt-3 mb-4">
              <a
                class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>

            <!-- Conteúdo principal -->
            <section class="fd-patiovirtual row mx-0">
              <div class="fd-patiovirtual-main px-3 py-2">
                <div class="d-flex fd-patiovirtual-main-conversas" :class="fastMobileCurrentChat ? 'fd-patiovirtual-main-conversa-aberta' : ''">
                  <div class="fd-patiovirtual-main-conversas-list">
                    <h3>Pátio Virtual</h3>
                    <div class="fd-patiovirtual-main-conversas-list-content p-3">
                      <h5 class="mb-0">Principal</h5>
                      <li><a href="#" class="text-white"><b-icon-house-door /> Todas as Plataformas</a></li>
                      <h5 class="mb-0 mt-2">Turmas</h5>
                      <li><a href="#" class="text-white"><b-icon-book/> Reclassificação</a></li>
                      <li><a href="#" class="text-white"><b-icon-book/> EJA EM</a></li>
                      <div class="d-flex justify-content-between mt-3 mb-1">
                        <h4>CHATS</h4>
                        <div class="d-flex justify-content-end">
                          <b-icon-search class="icon-search" role="button"/>
                          <input type="text" class="form-control col-11" placeholder="Pesquisar colega"/>
                        </div>
                      </div>
                      <ul class="overflow-auto">
                        <li class="d-flex justify-content-left chat-selected" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">Henrique Moraes</a><br/>
                            <span>Até mais! 😁</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-left mb-2" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">Vanessa Fernandes</a><br/>
                            <span>...... ..... .. ....</span>
                            <div class="efeito-blur"></div>
                          </div>
                        </li>
                        <li class="d-flex justify-content-left mb-2" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">Maria C.</a><br/>
                            <span>👏👏👏 ..... .....</span>
                            <div class="efeito-blur"></div>
                          </div>
                        </li>
                        <li class="d-flex justify-content-left mb-2" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">Turma EJA 2023</a><br/>
                            <span>Roberto: .. .......</span>
                            <div class="efeito-blur"></div>
                          </div>
                        </li>
                        <li class="d-flex justify-content-left mb-2" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">Robson Oliveira</a><br/>
                            <span>.....</span>
                            <div class="efeito-blur"></div>
                          </div>
                        </li>
                        <li class="d-flex justify-content-left mb-2" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">Sueli Bonfim</a><br/>
                            <span>......... ...</span>
                            <div class="efeito-blur"></div>
                          </div>
                        </li>
                        <li class="d-flex justify-content-left mb-2" role="button" @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat">
                          <div
                            class="p-4"
                            :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '40px', height: '40px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                          />
                          <div class="ml-2 text-wrap">
                            <a class="text-white" href="#">José Pedro</a><br/>
                            <span>... ........ ...</span>
                            <div class="efeito-blur"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="fd-patiovirtual-main-conversas-atual ml-3">
                    <h6 class="text-blue-patio mt-2 mb-4">
                      <b-icon-box-arrow-left 
                        v-if="fastMobileCurrentChat" 
                        role="button"
                        @click.prevent="fastMobileCurrentChat = !fastMobileCurrentChat" 
                        font-scale="1.5"
                        class="icon-sair-chat text-danger"
                      /> 
                      <b-icon-hash font-scale="1.5" />
                       Henrique Moraes
                    </h6>
                    <ul class="overflow-auto">
                      <li class="d-flex justify-content-left mr-2 my-2">
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                        <div class="text-left mr-auto ml-2">
                          <a class="text-orange" href="#">Henrique Moraes <small class="text-secondary">Hoje 10:23</small></a>
                          <p>Olá, como vão os estudos?</p>
                        </div>
                      </li>
                      <li class="d-flex justify-content-right mr-2 my-2">
                        <div class="text-right ml-auto mr-2">
                          <a class="text-blue-patio" href="#">Gisele Pereira Santos <small class="text-secondary">Hoje 10:26</small></a>
                          <p>.... ... ......... .... .. .......</p>
                          <div class="efeito-blur"></div>
                        </div>
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                      </li>
                      <li class="d-flex justify-content-right mr-2 my-2">
                        <div class="text-right ml-auto mr-2">
                          <a class="text-blue-patio" href="#">Gisele Pereira Santos <small class="text-secondary">Hoje 10:27</small></a>
                          <p>... ........ ......</p>
                          <div class="efeito-blur"></div>
                        </div>
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                      </li>
                      <li class="d-flex justify-content-left mr-2 my-2">
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                        <div class="text-left mr-auto ml-2">
                          <a class="text-orange" href="#">Henrique Moraes <small class="text-secondary">Hoje 10:36</small></a>
                          <p>.....</p>
                          <div class="efeito-blur"></div>
                        </div>
                      </li>
                      <li class="d-flex justify-content-right mr-2 my-2">
                        <div class="text-right ml-auto mr-2">
                          <a class="text-blue-patio" href="#">Gisele Pereira Santos <small class="text-secondary">Hoje 10:39</small></a>
                          <p>.... ... ......... .... .. .......</p>
                          <div class="efeito-blur"></div>
                        </div>
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                      </li>
                      <li class="d-flex justify-content-left mr-2 my-2">
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                        <div class="text-left mr-auto ml-2">
                          <a class="text-orange" href="#">Henrique Moraes <small class="text-secondary">Hoje 10:41</small></a>
                          <p>........ ..... .. ........ ..... ... ........</p>
                          <div class="efeito-blur"></div>
                        </div>
                      </li>
                      <li class="d-flex justify-content-right mr-2 my-2">
                        <div class="text-right ml-auto mr-2">
                          <a class="text-blue-patio" href="#">Gisele Pereira Santos <small class="text-secondary">Hoje 10:41</small></a>
                          <p>.... ... ......... .... .. .......</p>
                          <div class="efeito-blur"></div>
                        </div>
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                      </li>
                      <li class="d-flex justify-content-left mr-2 my-2">
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                        <div class="text-left mr-auto ml-2">
                          <a class="text-orange" href="#">Henrique Moraes <small class="text-secondary">Hoje 10:42</small></a>
                          <p>..... ... ..... .. .........</p>
                          <div class="efeito-blur"></div>
                        </div>
                      </li>
                      <li class="d-flex justify-content-left mr-2 my-2">
                        <div
                          class="px-4"
                          :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '50px', height: '50px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%', backgroundPosition: 'top' }"
                        />
                        <div class="text-left mr-auto ml-2">
                          <a class="text-orange" href="#">Henrique Moraes <small class="text-secondary">Hoje 10:42</small></a>
                          <p>Até mais! 😁</p>
                        </div>
                      </li>
                    </ul>
                    <div class="row mx-1">
                      <b-icon-emoji-smile role="button" font-scale="1.7" class="icon-emoji-smile"/>
                      <textarea type="text" class="form-control"></textarea>
                      <b-icon-cursor role="button" font-scale="1.5" class="icon-cursor" @click="enviarMsg()"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fd-patiovirtual-online px-3 py-2">
                <h5 class="mb-0">Alunos Online</h5>
                <ul class="overflow-auto mt-2">
                  <li class="d-flex justify-content-left mb-1" role="button">
                    <div
                      class="p-3"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '30px', height: '30px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
                    />
                    <div class="ml-2 my-auto">
                      <a class="text-white" href="#">Henrique Moraes</a><br/>
                    </div>
                  </li>
                  <li class="d-flex justify-content-left mb-1" role="button">
                    <div
                      class="p-3"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '30px', height: '30px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
                    />
                    <div class="ml-2 my-auto">
                      <a class="text-white" href="#">Sueli Bonfim</a><br/>
                    </div>
                    <div class="efeito-blur"></div>
                  </li>
                  <li class="d-flex justify-content-left mb-1" role="button">
                    <div
                      class="p-3"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '30px', height: '30px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
                    />
                    <div class="ml-2 my-auto">
                      <a class="text-white" href="#">José Pedro</a><br/>
                    </div>
                  </li>
                  <li class="d-flex justify-content-left mb-1" role="button">
                    <div
                      class="p-3"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/app/avatar-chat.png') + ')', width: '30px', height: '30px', margin: '0',  borderRadius: '50%',  backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
                    />
                    <div class="ml-2 my-auto">
                      <a class="text-white" href="#">Maria C.</a><br/>
                    </div>
                  </li>

                </ul>
              </div>
            </section>
            <!-- /Conteúdo principal -->

          </div>
        </div>
      </div>
      <!-- /fd-wrap -->
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  
  export default {
    name: "HomeInternoAlunoPatioVirtual",
    components: {
  
    },
    mixins: [methods],
    data: function () {
      return {
        fastTemplate: settings.fastTemplate,
        fastMobileCurrentChat: false
      };
    },
    mounted: function () {
      // Captura ID da plataforma pela URL
      if (this.$route.params.id_plataforma) {
        // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
        this.getFastDependecias(this.$route.params.id_plataforma, "aluno")
          .then(() => {
            // Iniciar aqui
            this.enviarMsg()
            this.$store.state.fastCarregando = false;
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    methods: { 
      enviarMsg(){
        this.exibeToasty('O Pátio Virtual não está habilidado nesta plataforma', 'error')
      }
    },
  };
  </script>
  
  <style scope>

  .efeito-blur{
    width: 100%;
    height: 100%;
    margin-top: -50px !important;
    backdrop-filter: blur(3px);
  }
  </style>